import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Editor } from 'tinymce';
import { IModalContext } from '../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { Token, UnsubscribeLinkPlaceholder } from '../../../models/Token';
import { ToolbarSignature, createRichContentEditorStateWithText } from '../../../models/UiUtils';
import { IRichContentEditorState } from '../../../viewmodels/AppViewModels';
import { brandSecondary } from '../../styles/colors';
import { CanSpamLink } from '../CanSpamLink';
import { IRichContentDocumentEditorConfig, RichContentDocumentEditor } from '../richContent/RichContentDocumentEditor';
import { styleSheet } from './styles';

interface IProps
	extends IModalContext,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IToasterComponentProps,
		IEventLoggingComponentProps {
	className?: string;
	contentEditorState?: IRichContentEditorState;
	editorStyles?: StyleDeclarationValue[];
	hideInstructions?: boolean;
	isSetup?: boolean;
	onContentEditorStateChanged?(contentState: IRichContentEditorState): void;
	styles?: StyleDeclarationValue[];
}

// This should stay synced up with /app/web/components/richContent/RichContentDocumentEditor/placeholders.tinymce.css
const placeholderRawCSS = `
	span[data-placeholder="true"] {
		border-radius: 3px;
		border: 1px dashed ${brandSecondary};
		box-sizing: border-box;
		padding: 1px 5px;
	}

	span[data-placeholder="true"][contentEditable=false] {
		cursor: pointer;
	}

	span[data-placeholder="true"][contentEditable=false][data-mce-selected] {
		cursor: pointer;
		outline: none;
	}
`;

export const DefaultUnsubscribeTemplateContent = createRichContentEditorStateWithText(
	"If you'd prefer not to hear from me by email, please reply back and let me know and I'll update my records accordingly."
);

class _UnsubscribeSignature extends React.Component<IProps> {
	private mEditorRef: Editor;

	public render() {
		const { className, contentEditorState, editorStyles, hideInstructions, isSetup, styles } = this.props;
		const config: IRichContentDocumentEditorConfig = {
			contentRawCss: placeholderRawCSS,
			imageOptions: { base64EmbedOnInsert: true },
			toolbar: isSetup ? '' : [ToolbarSignature],
		};

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} unsubscribe-signature ${className || ''}`}>
				{!hideInstructions && (
					<div className={css(styleSheet.promptText)}>
						{'To comply with the '}
						<CanSpamLink isSetup={isSetup} />, we recommend adding some text to the bottom of your email signature to
						allow recipients to opt out of receiving emails from you. This text will be automatically added to emails
						with more than 5 recipients.
					</div>
				)}
				<RichContentDocumentEditor
					className={css(isSetup ? styleSheet.editorSmall : styleSheet.editor, ...(editorStyles || []))}
					config={config}
					contentState={contentEditorState}
					onContentStateChanged={this.onContentEditorStateChanged}
					readOnlyUseFullEditor={true}
					onLoad={this.onLoad}
				/>
			</div>
		);
	}

	private onLoad = (editor?: Editor) => {
		if (editor) {
			this.mEditorRef = editor;
		}
	};

	private sanitizeContent = (unsubscribeContentEditorState: IRichContentEditorState) => {
		const content = unsubscribeContentEditorState.getRawRichTextContent().document;
		try {
			const [newContent] = Token.replaceContent(content, UnsubscribeLinkPlaceholder);
			return newContent;
		} catch {
			return content;
		}
	};

	private onContentEditorStateChanged = (unsubscribeContentEditorState: IRichContentEditorState) => {
		const { onContentEditorStateChanged } = this.props;
		const content = unsubscribeContentEditorState.getRawRichTextContent().document;
		const newContent = this.sanitizeContent(unsubscribeContentEditorState);
		if (newContent !== content) {
			onContentEditorStateChanged?.(createRichContentEditorStateWithText(newContent));
			// set the cursor after the token
			const element = this.mEditorRef.dom.doc.querySelector(`span[data-placeholder=true]`);
			if (element) {
				this.mEditorRef.selection.select(element);

				this.mEditorRef.selection.collapse(false);
			}
		} else {
			onContentEditorStateChanged?.(unsubscribeContentEditorState);
		}
	};
}

const UnsubscribeSignatureAsObserver = observer(_UnsubscribeSignature);
const UnsubscribeSignatureWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ToasterViewModelKey
)(UnsubscribeSignatureAsObserver);
export const UnsubscribeSignature = withEventLogging(UnsubscribeSignatureWithContext, 'UnsubscribeSignature');
